import React, { useRef } from "react";
import { Helmet } from "react-helmet";

import "@/components/layout.css";
import LandingSection from "@/components/LandingSection";
import ContactSection from "@/components/ContactSection";
import BeerOfMonthSection from "@/components/BeerOfMonthSection";
import GallerySection from "@/components/GallerySection";
import Navigation from "@/components/Navigation/Navigation";
import Offer from "@/components/Offer/Offer";
import { NavigationItem } from "@/types";
import News from "@/components/News/News";
import Team from "@/components/Team/Team";
import { content as metaDescription } from "@/content/json/offer.json";
import { items as openingItems } from "@/content/json/openings.json";

const keywords = [
  "Rathaus Bar",
  "Rathaus Bar Bern",
  "Restaurant Rathaus",
  "Bar Bern",
  "Bern",
  "Bar",
  "Rathaus",
];

const sections = {
  [NavigationItem.Home]: null,
  [NavigationItem.Offer]: null,
  [NavigationItem.BeerOfMonth]: null,
  [NavigationItem.News]: null,
  [NavigationItem.Gallery]: null,
  [NavigationItem.Team]: null,
  [NavigationItem.Contact]: null,
};

type Sections = Record<NavigationItem, HTMLElement | null>;

export default function Home() {
  const sectionRefs = useRef<Sections>(sections);
  const navigate = (item: NavigationItem) => {
    sectionRefs.current[item].scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: `de-CH`,
        }}
        title={`Rathaus Bar`}
        meta={[
          {
            name: `description`,
            content: metaDescription,
          },
          {
            name: `keywords`,
            content: keywords?.join(`,`),
          },
        ]}
      >
        <link
          rel="stylesheet"
          href="https://unpkg.com/leaflet@1.7.1/dist/leaflet.css"
          integrity="sha512-xodZBNTC5n17Xt2atTPuE1HxjVMSvLVW9ocqUKLsCC5CXdbqCmblAshOMAS6/keqq/sMZMZ19scR4PsZChSR7A=="
          crossOrigin=""
        />

        <script
          src="https://unpkg.com/leaflet@1.7.1/dist/leaflet.js"
          integrity="sha512-XQoYMqMTK8LvdxXYG3nZ448hOEQiglfqkJs1NOQV44cWnUrBc8PkAOcXy20w0vlaXaVUearIOBhiXZ5V3ynxwA=="
          crossOrigin=""
        ></script>
      </Helmet>

      <Navigation onClick={navigate} />
      <main>
        <section
          className="section full-height"
          ref={(ref) => (sectionRefs.current[NavigationItem.Home] = ref)}
        >
          <LandingSection items={openingItems} />
        </section>

        <section
          className="section full-height"
          ref={(ref) => (sectionRefs.current[NavigationItem.Offer] = ref)}
        >
          <Offer />
        </section>

        <section
          className="section full-height"
          ref={(ref) => (sectionRefs.current[NavigationItem.BeerOfMonth] = ref)}
        >
          <BeerOfMonthSection />
        </section>

        <section
          className="section full-height"
          ref={(ref) => (sectionRefs.current[NavigationItem.News] = ref)}
        >
          <News />
        </section>

        <section
          className="section full-height"
          ref={(ref) => (sectionRefs.current[NavigationItem.Gallery] = ref)}
        >
          <GallerySection />
        </section>

        <section
          className="section full-height"
          ref={(ref) => (sectionRefs.current[NavigationItem.Team] = ref)}
        >
          <Team />
        </section>

        <section
          className="section height-m contact-section"
          ref={(ref) => (sectionRefs.current[NavigationItem.Contact] = ref)}
        >
          <ContactSection onLogoClick={() => navigate(NavigationItem.Home)} />
        </section>
      </main>
    </>
  );
}
