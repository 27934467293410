import { useEffect, useState } from "react";

export default function useWindowDimensions() {
  const [dimensions, setDimensions] = useState({ height: 0, width: 0 });

  useEffect(() => {
    const func = () => {
      setDimensions({ width: window.innerWidth, height: window.innerHeight });
    };
    window.addEventListener("resize", func);
    func();
    return () => {
      window.removeEventListener("resize", func);
    };
  }, []);

  return { ...dimensions };
}
