import React from "react";
import Button from "@/components/Button/Button";
import { content } from "@/content/json/news.json";

export default function News() {
  return (
    <div className={`flex flex-1 content-wrapper>`}>
      <div className="content-padding flex flex-1 align-center justify-center flex-col font-center">
        <p style={{ maxWidth: "600px" }} className="font-l mb-4">
          {content}
        </p>

        <Button
          variant="link"
          href="https://facebook.com/pg/rathausbarbern/events"
          target="_blank"
        >
          Events
        </Button>
      </div>
    </div>
  );
}
