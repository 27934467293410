import { ReactNode } from "react";

export interface BaseComponentProps {
  children?: ReactNode;
}

export interface Reference {
  uri: string;
  description: string;
  title: string;
  images: Array<string>;
}

export interface ReferenceGroup {
  uri: string;
  references: Array<Omit<Reference, "uri">>;
}

export enum NavigationItem {
  Home = "start",
  Offer = "offer",
  BeerOfMonth = "beerOfMonth",
  News = "news",
  Gallery = "gallery",
  Team = "team",
  Contact = "contact",
}
