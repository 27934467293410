import React from "react";
import loadable from "@loadable/component";
import useWindowDimensions from "@/hooks/useWindowDimensions";
import BorderedLogo from "./BorderedLogo/BorderedLogo";
import clsx from "clsx";

const ContactMap = loadable(() => import("./ContactMap"), { ssr: false });

const maxFullWidth = 1440;

export default function ContactSection({
  onLogoClick,
}: {
  onLogoClick: () => void;
}) {
  const { width } = useWindowDimensions();

  return (
    <>
      <div className="flex flex-1 flex-col align-end justify-end">
        <div
          style={{
            width: "100%",
            maxWidth:
              width !== 0 && width > 1200 ? maxFullWidth / 2 : "initial",
          }}
        >
          <div className="content-padding">
            <h2 className="font-l">Rathaus Bar</h2>
            <h2 className="font-l font-weight-normal">
              Gerechtigkeitsgasse 78
            </h2>
            <h2 className="font-l font-weight-normal">3011 Bern</h2>
            <h2 className="font-l font-weight-normal mb-4">Schweiz</h2>

            <div
              className={`flex ${clsx(
                width !== 0 && width < 540 ? "flex-col" : "flex-row"
              )}`}
            >
              <div
                className={`flex flex-1 flex-col justify-center ${
                  width !== 0 && width < 540 ? "mb-4" : ""
                }`}
              >
                <a href="tel:+41313323011">+41 31 332 30 11</a>
                <a href="mailto:info@rathausbar.ch">info(at)rathausbar.ch</a>
                <a
                  target="_blank"
                  href="https://www.instagram.com/rathausbarbern"
                  rel="noreferrer"
                >
                  Instagram
                </a>
                <a
                  target="_blank"
                  href="https://www.facebook.com/rathausbarbern"
                  rel="noreferrer"
                >
                  Facebook
                </a>
              </div>
              <div className="flex align-end">
                <BorderedLogo
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    onLogoClick();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-1 w-10">
        <ContactMap />
      </div>
    </>
  );
}
