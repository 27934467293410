import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import BgImage from "@/components/BgImage";
import * as _classes from "./BeerOfMonth.module.css";
import { createClasses } from "@/theme";
import JSONData from "@/content/json/beer-of-month.json";
import clsx from "clsx";

const classes = createClasses(_classes);
// const maxFullWidth = 1440;

const month = {
  0: "Januar",
  1: "Februar",
  2: "März",
  3: "April",
  4: "Mai",
  5: "Juni",
  6: "Juli",
  7: "August",
  8: "September",
  9: "Oktober",
  10: "November",
  11: "Dezember",
};

export default function BeerOfMonthSection() {
  const { image } = useStaticQuery(
    graphql`
      query {
        image: file(
          relativePath: { eq: "content/images/beer-of-month/current.png" }
        ) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    `
  );

  return (
    <div className={`flex flex-1 content-wrapper ${classes.root}`}>
      <div className="flex flex-1 align-center justify-end content-padding-b">
        <div className="flex align-end h-10 w-10">
          <div className="flex flex-1 align-end h-8 content-padding-l">
            <h1 className={clsx(classes.verticalTextBig, "uppercase")}>
              MONATSBIER
            </h1>
            <h1 className={clsx(classes.verticalTextBig, "uppercase")}>
              {month[new Date().getMonth()]}
            </h1>
          </div>
          <BgImage
            className={classes.image}
            alt="Monatsbier"
            image={getImage(image)}
          />
        </div>
      </div>
      <div className="flex flex-1 flex-col align-center justify-center">
        <div className="content-padding flex flex-col justify-center">
          <div className="mb-4">
            <h2>{JSONData.name}</h2>
            <h3>{JSONData.factory}</h3>
            <h4>{JSONData.category}</h4>
            <p>{JSONData.content}</p>
            <p>{JSONData.attributes}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
