import React from "react";
import LogoCircle from "@/components/LogoCircle";
import { NavigationItem } from "@/types";

export interface MainNavigationProps {
  onClick: (item: NavigationItem) => void;
  items: Partial<Record<NavigationItem, string>>;
}

export default function MainNavigation({
  onClick,
  items,
}: MainNavigationProps) {
  const withPreventDefault = (key: NavigationItem) => (e: React.MouseEvent) => {
    e.preventDefault();
    onClick(key);
  };

  return (
    <div>
      <div>
        <a href="#home" onClick={withPreventDefault(NavigationItem.Home)}>
          <LogoCircle width={100} />
        </a>
      </div>

      <ul>
        {Object.entries(items).map(([key, label]) => (
          <li key={key}>
            <a
              href="#"
              aria-label={label}
              className="font-xl"
              onClick={withPreventDefault(key as NavigationItem)}
            >
              {label}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}
