import React from "react";

import * as _classes from "@/components/LandingSection.module.css";
import { createClasses } from "@/theme";

const classes = createClasses(_classes);

export interface LandingProps {
  items: Array<{
    title: string;
    content: string;
  }>;
}

export default function LandingSection({ items }: LandingProps) {
  return (
    <div className="content-wrapper flex flex-col align-end justify-end w-10">
      <div className={`content-padding ${classes.openings} font-right`}>
        <h2 className="font-xl">Öffnungszeiten</h2>
        {items.map(({ title, content }) => (
          <React.Fragment key={title}>
            <h2 className="font-xl">{title}</h2>
            <h2 className="font-xl font-weight-normal">{content}</h2>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}
