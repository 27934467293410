import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Gallery from "@browniebroke/gatsby-image-gallery";
import Button from "./Button/Button";

export default function LandingSection() {
  const data = useStaticQuery(
    graphql`
      query {
        images: allFile(
          filter: { relativeDirectory: { eq: "content/images/gallery" } }
          sort: { fields: name }
        ) {
          edges {
            node {
              childImageSharp {
                thumb: gatsbyImageData(
                  width: 600
                  height: 600
                  placeholder: BLURRED
                )
                full: gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    `
  );
  const images = data.images.edges.map(({ node }) => ({
    ...node.childImageSharp,
  }));
  const [pages, setPages] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    if (!pages.length) {
      const pageSize = 6;
      const pageN = Math.ceil(images.length / pageSize);
      const paginatedData = (data: [], currentPage: number) => {
        const startIndex = currentPage * pageSize - pageSize;
        const endIndex = startIndex + pageSize;
        return data.slice(startIndex, endIndex);
      };

      const pages = [];
      for (let i = 0; i < pageN; i++) {
        pages.push([...paginatedData(images, i + 1)]);
      }

      setPages(pages);
    }
  }, [images]);

  const handleLoadMore = (e) => {
    e.stopPropagation();
    setCurrentPage((prev) => prev + 1);
  };

  const getImages = () => {
    return [...pages.slice(0, currentPage + 1)].reduce(
      (acc, curr) => [...acc, ...curr],
      []
    );
  };

  const hasNextPage = currentPage + 1 < pages.length;

  return (
    <div className="content-wrapper flex flex-1 flex-col align-center">
      <div className="content-padding flex flex-col align-center">
        <div className="mb-4">
          <Gallery images={getImages()} colWidth={100} mdColWidth={100 / 3} />
        </div>
        {hasNextPage && (
          <Button onClick={(e) => handleLoadMore(e)}>Mehr</Button>
        )}
      </div>
    </div>
  );
}
