import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Gallery from "@browniebroke/gatsby-image-gallery";
import JSONData from "@/content/json/team.json";

import * as _classes from "./Team.module.css";
import { createClasses } from "@/theme";
import clsx from "clsx";

const classes = createClasses(_classes);
interface ImageData {
  name: string;
  content: string;
  title: string;
}

const { members } = JSONData;

function Wrapper(data: Array<ImageData>) {
  return function CustomWrapper({ children, onClick }) {
    const image = children?.props?.image?.images?.fallback?.src;
    const { content, title } =
      data.find((item) => image.includes(item.name)) || {};

    return (
      <div
        className={`my-custom-image-wraper flex flex-row mb-2 ${classes.teamGalleryWrapper}`}
      >
        <div onClick={onClick}>{children}</div>
        <div className={clsx("flex", "align-center", classes.textWrapper)}>
          <div className={clsx("content-padding", classes.text)}>
            <h2>{title}</h2>
            <p
              className={clsx("font-l")}
              style={{ maxWidth: "400px", margin: 0 }}
            >
              {content}
            </p>
          </div>
        </div>
      </div>
    );
  };
}

export default function Team() {
  const data = useStaticQuery(
    graphql`
      query {
        images: allFile(
          filter: { relativeDirectory: { eq: "content/images/team" } }
          sort: { fields: name }
        ) {
          edges {
            node {
              name
              ext
              childImageSharp {
                thumb: gatsbyImageData(
                  width: 600
                  height: 600
                  placeholder: BLURRED
                )
                full: gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    `
  );

  const findMember = (node) =>
    members.find(({ image }) => image === node.name + node.ext);

  const findMemberIndex = (node) =>
    members.findIndex(({ image }) => image === node.name + node.ext);

  const images = data.images.edges
    .filter(({ node }) => !!findMember(node))
    .sort((a, b) => {
      const nodeA = a.node;
      const nodeB = b.node;
      return findMemberIndex(nodeA) - findMemberIndex(nodeB);
    })
    .map(({ node }) => ({
      ...node.childImageSharp,
    }));

  const imageData = data.images.edges.map(({ node }) => ({
    name: node.name + node.ext,
    content: findMember(node)?.content,
    title: findMember(node)?.title,
  }));

  return (
    <div className="content-wrapper flex flex-1 flex-col align-center">
      <div className="content-padding flex flex-col align-center">
        <div className="mb-4 flex flex-col">
          <Gallery
            images={images}
            colWidth={100}
            mdColWidth={100 / 3}
            customWrapper={Wrapper(imageData)}
          />
        </div>
      </div>
    </div>
  );
}
