import React from "react";
import clsx from "clsx";

import * as _classes from "./Button.module.css";
import { createClasses } from "@/theme";

const classes = createClasses(_classes);

interface ButtonProps
  extends React.HTMLProps<HTMLAnchorElement | HTMLButtonElement> {
  children?: React.ReactNode;
  onClick?: (e: any) => void;
  variant?: "link" | "button";
}

export default function Button({
  children,
  onClick,
  className,
  variant = "button",
  ...rest
}: ButtonProps) {
  if (variant === "link") {
    return (
      <a className={clsx(classes.root, className)} {...(rest as any)}>
        {children}
      </a>
    );
  }

  return (
    <button
      onClick={onClick}
      className={clsx(classes.root, className)}
      {...(rest as any)}
    >
      {children}
    </button>
  );
}
