import React, { useState } from "react";
import clsx from "clsx";

import MainNavigation, {
  MainNavigationProps,
} from "@/components/Navigation/MainNavigation";
import MobileNavigation from "@/components/Navigation/MobileNavigation";

import * as _classes from "./Navigation.module.css";
import { createClasses } from "@/theme";
import useWindowDimensions from "@/hooks/useWindowDimensions";
import { NavigationItem } from "@/types";

const classes = createClasses(_classes);

const navigationItems: Omit<
  Record<NavigationItem, string>,
  NavigationItem.Home
> = {
  [NavigationItem.Offer]: "Angebot",
  [NavigationItem.BeerOfMonth]: "Monatsbier",
  [NavigationItem.News]: "Aktuelles",
  [NavigationItem.Gallery]: "Bilder",
  [NavigationItem.Team]: "Team",
  [NavigationItem.Contact]: "Kontakt",
};

export interface NavigationProps {
  onClick: (item: NavigationItem) => void;
}

export default function Navigation({ onClick }) {
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const { width } = useWindowDimensions();

  if (width === 0) {
    // prevent flash effect
    return null;
  }

  const handleMobileClick: MainNavigationProps["onClick"] = (...args) => {
    onClick(...args);
    setIsMobileOpen(false);
  };

  return (
    <nav className={clsx(isMobileOpen && classes.isActive)}>
      {width < 1240 ? (
        <MobileNavigation
          onClick={handleMobileClick}
          isActive={isMobileOpen}
          items={navigationItems}
          onToggleOpen={() => setIsMobileOpen(!isMobileOpen)}
        />
      ) : (
        <MainNavigation onClick={onClick} items={navigationItems} />
      )}
    </nav>
  );
}
