import React from "react";
import Button from "@/components/Button/Button";

import { content } from "@/content/json/offer.json";

export default function Offer() {
  return (
    <div className={`flex flex-1 content-wrapper>`}>
      <div className="content-padding flex flex-1 align-center justify-center flex-col font-center">
        <p style={{ maxWidth: "600px" }} className="font-l mb-4">
          {content}
        </p>

        <Button variant="link" href="/assets/menu.pdf" target="_blank">
          Angebot
        </Button>
      </div>
    </div>
  );
}
