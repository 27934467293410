import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import * as _classes from "./BorderedLogo.module.css";
import { createClasses } from "@/theme";

const classes = createClasses(_classes);

type BorderedLogoProps = React.AnchorHTMLAttributes<HTMLAnchorElement>;

export default function BorderedLogo({
  className,
  ...rest
}: BorderedLogoProps) {
  return (
    <a
      className={`flex justify-center align-center ${classes.link} ${className}`}
      {...rest}
    >
      <StaticImage
        src="../../content/images/logos/logo-single-letter.png"
        height={30}
        alt={"logo"}
      />
    </a>
  );
}
