import React from "react";
import { StaticImage } from "gatsby-plugin-image";

export default function LogoCircle(_: { width?: number }) {
  return (
    <div className={`flex align-center justify-center`}>
      <StaticImage
        src="../content/images/logos/logo-single-letter.png"
        alt="logo"
        height={150}
      />
    </div>
  );
}
