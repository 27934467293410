import React from "react";
import "hamburgers/dist/hamburgers.css";
import clsx from "clsx";
import { StaticImage } from "gatsby-plugin-image";

import * as _classes from "@/components/Navigation/MobileNavigation.module.css";
import { createClasses } from "@/theme";
import { NavigationItem } from "@/types";

const classes = createClasses(_classes);

export default function MobileNavigation({
  onClick,
  onToggleOpen,
  isActive: isOpen,
  items,
}: {
  isActive: boolean;
  onToggleOpen: () => void;
  onClick: (item: NavigationItem) => void;
  items: Partial<Record<NavigationItem, string>>;
}) {
  const withPreventDefault = (key: NavigationItem) => (e: React.MouseEvent) => {
    e.preventDefault();
    onClick(key);
  };

  return (
    <>
      <div className={clsx(classes.root, isOpen && classes.isActive)}>
        <div>
          <a href="#" onClick={withPreventDefault(NavigationItem.Home)}>
            <StaticImage
              src="../../content/images/logos/logo-single-letter.png"
              alt="rathausbar logo"
              height={100}
            />
          </a>
        </div>

        <div className="flex">
          <button
            className={`hamburger hamburger--collapse ${
              isOpen ? "is-active" : ""
            }`}
            type="button"
            onClick={() => onToggleOpen()}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </button>
        </div>
      </div>
      {isOpen && (
        <div className={`flex flex-1 justify-center ${classes.entries}`}>
          <ul className="flex-col justify-center mobile">
            {Object.entries(items).map(([key, label]) => (
              <li key={key}>
                <a
                  href="#"
                  className="font-2xl"
                  onClick={withPreventDefault(key as NavigationItem)}
                >
                  {label}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
}
